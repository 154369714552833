<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">涉嫌违法污染环境 — 厦门海沧非法炼油厂被端！</div>
      <p class="time">2018.06.22</p>

      <p class="space_content titleText" style="font-weight: 600;text-indent:0"> 案件回顾：</p>
      <p class="space_content">
        福建省厦门市环保局海沧分局执法人员在日常巡查时，途经天竺山附近郊区时，闻到空气中混杂着刺鼻的油料味，出于职业敏感，执法人员根据刺鼻气味情况分头展开排查，经过近1小时摸排，终于找到了产生刺鼻气味的源头——
        一个疑似土法炼油厂藏身于村庄内。
      </p>
      <p class="space_content">
        海沧环保分局联合区公安、安监、城管等部门开展突击联合执法。经现场检查，该炼油厂厂房约1000平方米，内有10多个数米高的储油罐，厂房内的桌子上还摆放着20几个矿泉水瓶装样品，执法人员从一本出货单油料进出库情况看，该厂每月销售量达到15吨左右，该炼油厂在炼油过程中，排放的废水、废气、废渣的处理均没有经过环保手续，直接排入土壤、水源中,这些油料将很难分解，造成了厂区的部分沙土被油料污染。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/13-1.png')">
        <p class="center_">（挖开沙土表层，可见沙土已被油料污染）</p>
      </div>

      <p class="space_content">
        执法人员在调查询问该厂经营者杜某时发现，该炼油厂系厦门某石油制品有限公司于2016年10月起在此开展生产经营，从龙岩买来氧化变黑的矿物油后，再经过过滤提纯等工艺，进行分罐储存，最终出售给周边的机械加工厂作为机台润滑油。该厂未办理环境影响评价相关手续、未配套环保处理设施，初步判断经营者涉嫌非法处置危险废物。
      </p>
      <p class="space_content">
        海沧环保部门为了取证过程的合法和严谨性，特别委托了省内具有
        <span style="font-weight: 600">
          环境物证鉴定资质
        </span>
        的
        <span style="font-weight: 600;color: red">
          福建历思司法鉴定所
        </span>
        ， 对现场的废原料油进行司法鉴定，检测是否为
        <span style="font-weight: 600">
          危险废物
        </span>
        。若废原料油经司法鉴定为危险废物且超过3吨，经营者将以环境污染犯罪被移送司法机关追究
        <span style="font-weight: 600">
          刑事责任
        </span>
        。
        <span style="font-weight: 600;color: red">
          福建历思司法鉴定所
        </span>
        接到委托后，第一时间赶到现场进行勘察，从原料油样品的采样阶段开始介入，
        <span style="font-weight: 600">
          对所有工艺原料进行采样与检测。最终检测结果该油厂的废原料油并不是危险废物，
        </span>
        并且出具了司法鉴定意见书作为案件办理的证据。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/13-2.png')">
        <p class="center_">（福建历思司法鉴定所工作人员在现场进行原料样品采样）</p>
      </div>

      <p class="space_content">
        <span style="font-weight: 600">
          海沧环保部门
        </span>
        根据
        <span style="font-weight: 600;color: red">
          福建历思司法鉴定所
        </span>

        出具的鉴定意见书，依法查封了该炼油厂的生产设施，虽然该厂废原料油司法鉴定的结果不是危险废物，但对周边的环境已造成了污染；
        <span style="font-weight: 600">
          虽不会追究经营者的刑事责任，但会追究其环境污染的责任。
        </span>


      </p>

      <p class="space_content titleText" style="font-weight: 600;text-indent:0">
        那么，为什么海沧环保部门选择
        <span style="color: red">
          福建历思司法鉴定所
        </span>
        做环境鉴定与检测呢？
      </p>

      <p class="space_content" style="text-indent:0">
        <span style="font-weight: 600">
          ● 福建历思司法鉴定所
        </span>
        在近十年来从事环境物证鉴定、环境污染损害鉴定评估等工作中，逐步建立了一支经验丰富的团队，精通环境调查、环境评价、环境风险管理、环境司法、环境科研、环境监测领域的专家组成，其中不少专家曾在跨国公司多年从事环境调查。
      </p>

      <p class="space_content" style="text-indent:0">
        ● 2006年，福建历思司法鉴定所取得国内首批环境物证鉴定牌照，是全国环境物证领域
        <span style="font-weight: 600">
        最早介入、最早研究、最早实践
        </span>
        的机构之一，先后承办了泉港皮革案群体性事件等福建重大特大环境案件。
      </p>

      <p class="space_content" style="text-indent:0">
        ● 2016年，福建历思司法鉴定所进入环保部环境损害鉴定评估推荐机构名录，在全国29家单位中拥有
        <span style="font-weight: 600">
      环境物证司法鉴定和环保部环境损害鉴定评估推荐机构双重资质。
        </span>
      </p>

      <p class="space_content" style="text-indent:0">
        ● 历思承担的《环境损害鉴定评估通用规范》福建省地方标准，于2017年12月发布并从 2018年3月25日起正式实施，该标准是目前
        <span style="font-weight: 600">
     国内该领域首个标准。
        </span>
      </p>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/12'"><p class="ellipsis1">
            厉害了，国内首个环境损害鉴定评估标准在福建省开始实施了！</p></router-link>


        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/14'"><p class="ellipsis1">
            厦门公安局集美分局王局长一行莅临福建历思司法鉴定所调研</p></router-link>
        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
